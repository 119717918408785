import React from 'react'

const iframeStyle = {
  width: '100%',
  height: '300px',
}

export default class JotformEmbed extends React.Component {
  constructor(props) {
    super(props)
    this.handleIframeMessage = this.handleIframeMessage.bind(this)
    this.iframeRef = React.createRef()
  }

  handleIframeMessage(e) {
    const props = this.props
    if (!e.data.split) {
      return
    }
    const args = e.data.split(':')
    const formId = args[2]
    const iframe = this.iframeRef.current
    if (!!iframe && (!formId || props.src.split('?')[0].endsWith(formId))) {
      switch (args[0]) {
        case 'scrollIntoView':
          iframe.scrollIntoView()
          break
        case 'setHeight':
          iframe.style.height = `${args[1]}px`
          break
        case 'collapseErrorPage':
          if (iframe.clientHeight > global.innerHeight) {
            iframe.style.height = `${global.innerHeight}px`
          }
          break
        case 'reloadPage':
          global.location.reload()
          break
      }
      const isJotForm = e.origin.indexOf('jotform') > -1
      if (
        isJotForm &&
        'contentWindow' in iframe &&
        'postMessage' in iframe.contentWindow
      ) {
        const urls = {
          docurl: encodeURIComponent(global.document.URL),
          referrer: encodeURIComponent(global.document.referrer),
        }
        iframe.contentWindow.postMessage(
          JSON.stringify({ type: 'urls', value: urls }),
          '*'
        )
      }
    }
  }

  componentDidMount() {
    if (global.addEventListener) {
      global.addEventListener('message', this.handleIframeMessage, false)
    } else if (global.attachEvent) {
      global.attachEvent('onmessage', this.handleIframeMessage)
    }
  }

  componentWillUnmount() {
    if (global.removeEventListener) {
      global.removeEventListener('message', this.handleIframeMessage, false)
    } else if (global.detachEvent) {
      global.detachEvent('onmessage', this.handleIframeMessage)
    }
  }

  render() {
    const props = this.props
    return (
      <iframe
        ref={this.iframeRef}
        style={iframeStyle}
        className={props.className}
        src={props.src}
        frameBorder={0}
        scrolling={props.scrolling ? 'yes' : 'no'}
      />
    )
  }

  shouldComponentUpdate(nextProps) {
    const props = this.props
    return (
      props.src !== nextProps.src ||
      props.className !== nextProps.className ||
      props.scrolling !== nextProps.scrolling
    )
  }
}
