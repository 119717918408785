import React from 'react'
import Layout from '../../components/layout/layout'
import { Heading, Box, Skeleton, Container } from '@chakra-ui/react'
import JotformEmbed from '../../components/functionality/jotformEmbed'
import { getGeoValue, useGeo } from '../../api/queries'
import TextContainer from '../../components/elements/textContainer'
import { eventData } from '../../api/eventData'
import useQueryParams from '../../hooks/useQueryParams'
import Event from '../../components/page/index/eventsSection/event'

function JotForm({ id }) {
  return <JotformEmbed src={`https://form.jotform.com/${id}`} />
}

export default function RegistrationPage({
  heading,
  jotformIds,
  formQueryParams,
  text,
  bgColor = 'white',
}) {
  const {
    geo: { country, region },
    isSuccess,
  } = useGeo()

  const formId =
    typeof jotformIds === 'object'
      ? getGeoValue({ data: jotformIds, country, region })
      : jotformIds

  return (
    <Layout fadeNav={false}>
      <Box pt={12} bgColor={bgColor}>
        <Heading textAlign={'center'} mb={8} color={'blue'}>
          {heading}
        </Heading>
        <Container>{text}</Container>
        <Box
          bgColor={'#ecedf3'}
          minHeight={'500px'}
          mt={{ base: 8, md: 16 }}
          id={'tickets'}
        >
          {isSuccess ? (
            <JotForm
              id={formId + (formQueryParams ? '?' + formQueryParams : '')}
            />
          ) : (
            <Skeleton height={'500px'} />
          )}
        </Box>
      </Box>
    </Layout>
  )
}

const EventPrice = ({ data, variant }) => {
  const {
    geo: { country, region },
    isSuccess,
  } = useGeo()

  const { price, eventPricing } = data

  if (!price && !eventPricing) return null

  if (variant === 'eventOnly') {
    const eventPrice = !isSuccess
      ? 'loading'
      : getGeoValue({ data: eventPricing, country, region })

    return (
      <>
        <Skeleton isLoaded={isSuccess} mt={0}>
          {eventPrice} -{' '}
          <strong>Event-only ticket, does not include book</strong>
        </Skeleton>
      </>
    )
  }

  return (
    <>
      <br />
      {price} + shipping
    </>
  )
}

export function EventRegistrationPage({
  title,
  subtitle,
  date,
  eventId,
  variant,
  formIds = { US: '211055464723148' },
}) {
  const data = eventData[eventId] || {}
  const { customStripe, isPast } = data

  // For different book stores we may want to use a different form
  const { formId: formIdFromQuery } = useQueryParams()
  const jotformIds = formIdFromQuery ? { US: formIdFromQuery } : formIds

  const formQueryParams = [
    `customStripe=${encodeURIComponent(customStripe)}`,
    `event_date=${encodeURIComponent(date)}`,
    `event_name=${encodeURIComponent(title)}`,
  ].join('&')

  return (
    <RegistrationPage
      heading={variant === 'bookstore' ? 'Karma Virtual Book Tour' : title}
      text={
        variant === 'bookstore' ? (
          <Event
            event={{
              ...data,
              title,
              eventId,
              buttons: [{ buttonText: 'Get Tickets', buttonLink: '#tickets' }],
            }}
            variant={'bookstorePage'}
          />
        ) : (
          <TextContainer textAlign={'center'}>
            {subtitle}
            {!isPast && (
              <>
                <br />
                {date}
              </>
            )}
            <EventPrice data={data} variant={variant} />
          </TextContainer>
        )
      }
      jotformIds={jotformIds}
      formQueryParams={formQueryParams}
      bgColor={variant === 'bookstore' ? 'tan.50' : 'white'}
    />
  )
}
