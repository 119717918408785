import React from 'react'
import PackageRegPage from './_package.js'
import { PatronPackageItems } from '../../components/page/package/elements'

export default function PatronPackagePage() {
  return (
    <PackageRegPage
      heading={'Patron Package'}
      jotformIds={{ US: '210967860163157', CA: '210978462450156' }}
      text={<PatronPackageItems />}
    />
  )
}
