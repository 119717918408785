import React from 'react'
import TextContainer from '../../components/elements/textContainer'
import RegistrationPage from './_regPage'

export default function PackageRegPage({ text, ...props }) {
  return (
    <RegistrationPage
      text={<TextContainer color={'blue'}>{text}</TextContainer>}
      {...props}
    />
  )
}
